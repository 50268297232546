export function CurrencyRUBIcon(props: React.SVGAttributes<{}>) {
  return (
    <svg
      viewBox="0 0 128 128"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect width="128" height="128" rx="64" fill="#232800" />
      <path
        d="M36.8571 64.1117H38.1071V62.8617V19.0446C38.1071 18.3297 38.6869 17.75 39.4018 17.75H74.0429C82.7806 17.75 90.1207 20.7018 95.2711 25.7642C100.419 30.8243 103.464 38.076 103.464 46.8387C103.464 55.6031 100.418 62.9578 95.2629 68.1183C90.1081 73.2789 82.7691 76.3214 74.0429 76.3214H54.3643H53.1143V77.5714V84.3571V85.6071H54.3643H88.5982C89.3131 85.6071 89.8929 86.1869 89.8929 86.9018V95.3839C89.8929 96.0988 89.3131 96.6786 88.5982 96.6786H54.3643H53.1143V97.9286V108.955C53.1143 109.67 52.5346 110.25 51.8196 110.25H39.4018C38.6869 110.25 38.1071 109.67 38.1071 108.955V97.9286V96.6786H36.8571H25.8303C25.1154 96.6786 24.5357 96.0988 24.5357 95.3839V86.9018C24.5357 86.1869 25.1154 85.6071 25.8303 85.6071H36.8571H38.1071V84.3571V77.5714V76.3214H36.8571H25.8303C25.1154 76.3214 24.5357 75.7417 24.5357 75.0268V65.4063C24.5357 64.6914 25.1154 64.1117 25.8303 64.1117H36.8571ZM54.3643 29.8283H53.1143V31.0783V62.8617V64.1117H54.3643H70.7857C76.1369 64.1117 80.5197 62.4488 83.5656 59.3746C86.6093 56.3026 88.1857 51.9551 88.1857 46.8387C88.1857 41.7832 86.6063 37.5016 83.5972 34.4809C80.5868 31.4591 76.2741 29.8283 71.0571 29.8283H54.3643Z"
        fill="white"
        stroke="#232800"
        stroke-width="2.5"
      />
    </svg>
  );
}
